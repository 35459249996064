<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Promotor de vendas"
            name="Promotor"
            placeholder="Promotor"
            v-model="notaSaida.nomePromotor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="notaSaida.praca"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a)"
            name="Vendedor(a)"
            placeholder="Vendedor(a)"
            v-model="notaSaida.nomeVendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="CPF"
            name="CPF"
            placeholder="CPF"
            v-model="notaSaida.cpf"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone"
            name="Telefone"
            placeholder="Telefone"
            v-model="notaSaida.telefone"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Endereço"
            name="Endereço"
            placeholder="Endereço"
            v-model="notaSaida.endereco"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-divider></v-divider>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-produto class="mr-2"></select-produto>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td v-currency="{ currency: 'BRL', locale: 'pt-br' }">{{ props.item.precoVenda }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidade"
                  required
                  :readonly="props.item.id != 0"
                ></v-text-field>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn v-if="!bloqueado" outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import ProgressCircular from "../progressCircular/progressCircular"
import SaidaAdicionarProduto from "../../domain/saida/SaidaAdicionarProduto"
import { ServicoSaida } from "../../servicos/servicoSaida"
import SelectProduto from "../produto/select/selectProdutos.vue"
const servicoSaida = new ServicoSaida()
export default {
  components: {
    SelectProduto,
    ProgressCircular
  },
  data() {
    return {
      snackbar: false,
      text: "",
      idRomaneio: "",
      notaSaida: "",
      produtos: [],
      produtosAdicionados: [],
      headers: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Preço de venda", align: "left", sortable: false, value: "precoVenda" },
        { text: "Quantidade", align: "left", sortable: false }
      ]
    }
  },
  created() {
    serverBus.$on("produtosSelecionados", value => {
      value.forEach(element => {
        if(this.produtos.filter(x => x.idProduto == element.idProduto && x.id == 0).length == 0) {
          this.produtos.push(element)
        }
      })
    })
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSaida.buscarPorId(this.id).then(res => {
        this.notaSaida = res.data
        this.idRomaneio = this.notaSaida.idRomaneio
        this.produtos = res.data.produtosResumidos
      })
    }
  },
  methods: {
    focusInput: function(inputRef) {
      this.$refs[inputRef].focus()
    },
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        if (this.produtos.length == 0) {
          this.fecharProgressCircular()
          alert("Não é possível salvar uma saída sem produtos vinculados.")
          return false
        }

        var notaSaida = new SaidaAdicionarProduto()
        notaSaida.id = this.notaSaida.id
        notaSaida.IdRomaneio = this.idRomaneio
        this.produtos
          .filter(x => x.id == 0)
          .forEach(element => {
            var produto = element
            produto.id = element.id != undefined ? parseInt(element.id) : 0
            produto.idProduto = parseInt(element.idProduto)
            produto.quantidade = parseInt(element.quantidade)
            produto.precoVenda = parseFloat(element.precoVenda)
            produto.semComissao = parseFloat(element.semComissao ? 1 : 0)
            notaSaida.Produtos.push(produto)
          })
        servicoSaida
          .adicionarProdutos(notaSaida)
          .then(res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.$router.push({ name: "saidaConsignada" })
            } else {
              this.fecharProgressCircular()
              this.snackbar = true
              this.text = res.data[0].title + " - " + res.data[0].detail
            }
          })
          .catch(error => {
            this.fecharProgressCircular()
            this.snackbar = true
            this.text = "Erro ao salvar uma nova saída " + error
            return Promise.reject(error)
          })
      }
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    }
  }
}
</script>
